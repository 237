<html>
<head>
  <meta charset="utf-8">
  <title>Toepen Oeleh</title> <!-- Change the title here -->
  <style> body { margin: 0 !important; } </style>
  <meta name="viewport" content="width=device-width, initial-scale=1, maximum-scale=1, user-scalable=no">
</head>


<body style="margin:0">
</body>
</html>
<template>
  <div id="app">
    <h1 class="title">Toepen ouleh</h1>

    <div class="input-container">
      <label for="newPlayer">Voeg speler toe:</label>
      <input v-model="newPlayer" />
      <button @click="addPlayer">Toevoegen</button>
    </div>

    <div v-if="players.length > 0">
      <h2 class="subtitle">Spelers</h2>
      <ul>
        <li v-for="(player, index) in players" :key="index" class="player-item" :class="{ 'out': player.score >= 15 }">
          <div class="player-info">
            <span class="player-name">{{ player.name }}</span>
            <span class="player-score">{{ player.score }}</span>
          </div>
          <div class="player-buttons">
            <button @click="increaseScore(index)" :disabled="player.score >= 15">+</button>
            <button @click="decreaseScore(index)" :disabled="isGameOver">-</button>
            <button @click="confirmRemovePlayer(index)" :disabled="isGameOver">x</button>
          </div>
        </li>
      </ul>
    </div>
     <div>
      <button @click="confirmReset" :disabled="!isGameOver && players.length === 0">Reset Scores</button>
       <div>
      <label for="soundSelect">Selecteer geluid:</label>
      <select v-model="selectedSound" id="soundSelect">
        <option value="horn">Hoorn</option>
        <option value="old_horn">Oude hoorn</option>
        <option value="moan">Kreun</option>
        <!-- Add more options as needed -->
      </select>
      <button @click="playSound">Speel Geluid</button>
    </div>
      <div v-if="showPaymentPage"  class="modal">
        <div class="modal-content">
          <h2 v-if="winner">{{ winner.name }} heeft gewonnen!</h2>
          <h2 v-else>Bedankt voor het gebruik van de app!</h2>
          <p>De code van de app draait op bier!<br/> Maak de app nog beter en voer hem met bier</p><br/><br/>
          <button @click="payBeer" class="button">Betaal voor bier</button>
          <button @click="closePaymentPage" class="button">Nee, bedankt</button>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
   title () {
    return 'asdf'
  },
  data() {
    return {
      newPlayer: '',
      players: JSON.parse(localStorage.getItem('toepen_players')) || [],
      isGameOver: false,
      winner: null,
      showPaymentPage: false,
      hideModal: false,
      sounds:  {
        horn: new Audio('https://www.myinstants.com/media/sounds/air-horn-club-sample_1.mp3'), // Change paths to your sound files
        moan: new Audio('https://www.myinstants.com/media/sounds/moann_AseCmdj.mp3'),
        old_horn: new Audio('https://www.myinstants.com/media/sounds/old-car-horn.mp3'),
        // Add more sound options as needed
      },
    };
  },
  watch: {
    players: {
      handler() {
        localStorage.setItem('toepen_players', JSON.stringify(this.players));
      },
      deep: true,
    },
  },
  mounted(){
  document.title = 'Toepen Oeleh'
  },
  methods: {
    addPlayer() {
      if (this.newPlayer.trim() !== '') {
        this.players.push({ name: this.newPlayer, score: 0 });
        this.newPlayer = '';
      }
    },
    removePlayer(index) {
      this.players.splice(index, 1);
      this.checkGameOver();
    },
    increaseScore(index) {
      this.players[index].score += 1;
      this.checkGameOver();
    },
    decreaseScore(index) {
      this.players[index].score -= 1;
      this.checkGameOver();
    },
    resetScores() {
      this.players.forEach(player => {
        player.score = 0;
      });
      this.isGameOver = false;
      this.winner = null;
    },
    checkGameOver() {
      const winner = this.players.find(player => player.score >= 15);
      if (winner) {
        this.isGameOver = true;
        this.winner = winner;
      }
    },
    confirmReset() {
      if (window.confirm("Weet je zeker dat je de scores wilt resetten?")) {
        this.openPaymentPage();
      }
    },
    confirmRemovePlayer(index) {
      if (window.confirm("Weet je zeker dat je deze speler wilt verwijderen?")) {
        this.removePlayer(index);
      }
    },
    playSound() {
      const selectedSound = this.sounds[this.selectedSound];
      if (selectedSound) {
        selectedSound.play();
      }
    },
    hideModalForTwoHours() {
      this.hideModal = true;
      setTimeout(() => {
        this.hideModal = false;
      }, 2 * 60 * 60 * 1000); // 2 hours in milliseconds
    },
    openPaymentPage() {
      this.showPaymentPage = true;
    },
    closePaymentPage() {
      this.showPaymentPage = false;
        this.resetScores();
    },
    payBeer() {
      window.open(  "https://leet.onl/pay", "_blank");
      this.hideModalForTwoHours();
      this.closePaymentPage();
      this.resetScores();
    },
  },
};
</script>
<style>
* {
  box-sizing: border-box;
  padding: 0;
  margin: 0;

  touch-action: pan-x pan-y;
}
h1,h2,h3,h4,h5,h6{
  color: #fff;
}
body{

  touch-action: pan-x pan-y;
}
</style>
<style scoped>
.modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  color:#000;
}
.modal h2{
  color:#000;
  margin-bottom:15px;

}
.modal-content {
  background-color: #fff;
  padding: 20px;
  border-radius: 5px;
  text-align: center;
}

html, body {
  touch-action: pan-x pan-y;

      overscroll-behavior-y: none;
}
#app {
  font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
  text-align: center;
  color: #fff;
  background-color: #333;
  padding: 20px;
  margin:0px;
  min-height: 100vh;
}

.title {
  font-size: 1.5em;
  margin-bottom: 20px;
}

.subtitle {
  font-size: 1.2em;
  margin-top: 20px;
}

.input-container {
  margin-bottom: 10px;
}

label {
  display: block;
  margin-bottom: 5px;
}

input {
  width: 60%;
  padding: 5px;
  margin-right: 5px;
}

button {
  padding: 15px 15px;
  margin-left: 5px;
  background-color: #555;
  color: #fff;
  border: none;
  cursor: pointer;
}

ul {
  padding: 0;
  list-style: none;
}

.player-item {
  border: 1px solid #555;
  border-radius: 5px;
  margin-bottom: 10px;
  padding: 10px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #444;
}

.player-buttons button {
  margin-left: 5px;
}

.player-name {
  font-weight: bold;
}

.player-score {
  margin-left: 10px;
}

.out {
  background-color: #ff5555;
}

.game-over {
  margin-top: 20px;
  padding: 10px;
  background-color: #555;
  border-radius: 5px;
}
input,
select {
  padding: 10px 20px;
  font-size: 16px;
  border: none;
  border-radius: 5px;
  margin-right: 10px;
  background:#444;
  border:1px solid #fff;
  color:#fff;
}

.button {
  background-color: #4CAF50;
  color: white;
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  margin-right: 10px;
}

.game-over .button,
.payment-page .button {
  margin: 10px 0;
}
</style>
